import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useDigitalVillageStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      bannerCase: file(
        relativePath: {
          eq: "case-study/digital-village/Digital-Village-project.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      bannerCaseRing: file(
        relativePath: { eq: "case-study/digital-village/banner-rings.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: {
          eq: "case-study/digital-village/Search-on-the-Digital-Village-website.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: {
          eq: "case-study/digital-village/Kanban-board-on-the-Digital-Village-website.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: {
          eq: "case-study/digital-village/Account-management-on-Digital-Village.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: {
          eq: "case-study/digital-village/Users-profile-on-Digital-Village.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: {
          eq: "case-study/digital-village/Channel-administration-for-Digital-Village.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: {
          eq: "case-study/digital-village/Admin-part-on-the-Digital-Village-website.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSeven: file(
        relativePath: {
          eq: "case-study/digital-village/Design-for-Digital-Village.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useDigitalVillageStaticQuery
