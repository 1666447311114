import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import solutionBgRingOne from '~/assets/images/case-study/digital-village/rings-lg.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'

import * as containerStyles from './DigitalVillage.module.scss'
import useDigitalVillageStaticQuery from './useDigitalVillageStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'Jason Hardie',
    position: 'Founder of The Digital Village Australia',
  },
  be: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewTraderVsTitle = 'Recreational vehicles marketplace platform'
const previewPmkTitle = 'Online Service Marketplace'

const CaseStudiesDigitalVillage = () => {
  const query = useDigitalVillageStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const bannerCaseRing = getImage(fileToImageLikeData(query.bannerCaseRing))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Collaboration marketplace for Digital Village"
          subtitle="Learn how Codica helped the Digital Village collaboration marketplace create a team-based project visualization tool and a smart contract system that attracted thousands of users worldwide."
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Collaboration platform"
          imageTitle="Collaboration marketplace"
          imageAbsolute={bannerCaseRing}
          classNameImageAbsolute={containerStyles.caseStudyHead__imageAbsolute}
          domain="Project management"
          location="Sydney, Australia"
          timeline="July 2019 - February 2020"
          services={
            <>
              Prototyping, Front-end, Back-end,
              <Link
                to="/services/ui-ux-design/"
                className="ml5"
                key="designKey"
              >
                UX/UI design services
              </Link>
            </>
          }
          team="1 Project Manager, 2 UX/UI Designers, 8 Software Developers, 1 QA Engineer"
          technologies="Ruby on Rails, PostgreSQL, React, Redux, AWS"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“The Codica team have been terrific to work with. The ability to interpret business needs and apply them with little guidance has been of great value for our startup.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-dv.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="Digital Village is an Australia-based online service marketplace bringing together entrepreneurs and developers for making excellent software products. Providing an enjoyable place for collaboration even locally, Digital Village uses bleeding-edge technology and work-life balance philosophy, paying particular attention to the importance of live communication."
        bgTextRight="The union contacted us for custom marketplace development. They wanted our specialists to build a convenient collaboration platform where an entrepreneur could find and cooperate with high-skilled designers and developers. Working in close-knit teams, all of them would experience simplicity and ease of use."
        sectionBusiness={[
          {
            label:
              'Establish a collaboration platform, combining the comprehensiveness of the web app and the transparency of the work processes.',
          },
          {
            label:
              'Create a project management tool with a simple-in-use yet high-powered administration board.',
          },
          {
            label:
              'Build an integrated control system that facilitates the coordination and management of the team.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Created a practical project management platform that incorporates one role for clients and three roles for specialists. All of these roles are divided by access to different functionality.',
          },
          {
            label:
              'Integrated a general admin panel to simplify the management and database administration on the online marketplace website.',
          },
          {
            label:
              'Implemented advanced search options in the Digital Village project for convenient collaboration between entrepreneurs, software developers, and designers.',
          },
          {
            label:
              'Developed clear UI and smooth UX design of the online marketplace website to increase user engagement.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“It was an amazing experience to work on the Digital Village platform. We aimed to build a secure, scalable, and complex RoR web solution and contribute to its success.',
            },
            {
              text: 'At Codica, we apply core technologies that are proven by years, use the recent trends, and explore new tools to achieve the maximum efficiency possible.',
            },
            {
              text: 'For now, Digital Village is a tremendous development platform with a large variety of opportunities for both clients and development teams. It is still expanding its borders, and we expect it to become a real Village for Agencies and independent contractors.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Sergey.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build a SaaS application?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Advanced search for professionals"
          description={[
            'As a part of custom marketplace development, we created an advanced search to build a team. Besides the listing of professionals, the Search page contains search by keywords.',
            <div className="mb-2" key="descr" />,
            'Also, it contains basic filters, such as Project and Hourly Rate of a specialist, and advanced, like their experience level and project interest. So, it becomes easier to find an appropriate team member.',
          ]}
          alt="Example of an advanced search by collaboration marketplace"
          imageTitle="Example of an advanced search on the Digital Village website"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Lean project management platform"
          description={[
            'Each project needs clear and transparent management. That’s why we provided the application with an integrated control system. It facilitates coordination and management of the team.',
            <div className="mb-2" key="descr" />,
            'Also, we integrated the Kanban board. It represents a multifunctional but easy handling project management tool that allows tracking the development progress.',
          ]}
          alt="Kanban board on the collaboration marketplace"
          imageTitle="Kanban board on the Digital Village website"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Several user roles"
          description={
            <>
              With
              <Link
                key="keyCaseSotutionMv"
                to="/services/multi-vendor-marketplace-development/"
                style={{ color: '#232323' }}
                className="mx5"
              >
                multi vendor marketplace development
              </Link>
              we provided an option for users to sign up as a client or as a
              specialist. Fundamentally, there is one role for a client and
              three roles for specialists: active developer, core developer, and
              producer.
              <div className="mb-2" key="descrOne" />
              The Client is the business owner receiving the service provided by
              the smart Digital Village platform. When a user signs up as a
              Client, they create a project and submit it to a Producer.
              <div className="mb-2" key="descrTwo" />A producer runs the project
              from start to finish. Active and core developers can participate
              in a project development via invitation from a Producer.
            </>
          }
          alt="Example of account management by collaboration platfrom"
          imageTitle="Example of account management on the Digital Village website"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredFour}
          title="Comprehensive specialist profiles"
          description="Working on the collaboration software development, we customized the profiles of users in such a way that they represent a full specialist’s background, including contact information, services they provide, and even records on a company they work in."
          alt="Example of a user profile by collaboration marketplace"
          imageTitle="Example of a user profile on the Digital Village website"
          bgRings
          bgRingImage={solutionBgRingOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Channel administration"
          description={[
            'Channel is a separate space where specialists collaborate to deliver software products.',
            <div className="mb-2" key="descrOne" />,
            'As it often happens in the collaboration software development process, a team may need additional workers. Thus, a Producer can add new members to a channel or remove them when necessary.',
            <div className="mb-2" key="descrTwo" />,
            'Since there are many tasks for a project and subdivisions inside teams, we integrated channels for each of them. Thus, it keeps all the team members from the mess and saves from the discussion of issues that are outside the scope.',
          ]}
          alt="Example of channel administration by collaboration marketplace"
          imageTitle="Example of channel administration on the Digital Village website"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Admin part"
          description={
            <>
              Working on
              <Link
                key="keyCaseSolutionOnlineM"
                to="/services/online-marketplace-development/"
                style={{ color: '#232323' }}
                className="ml5"
              >
                online marketplace development
              </Link>
              , we integrated a general admin panel. The general admin part is
              used to manage the content on the website. It is an open-source
              CMS (content management system) for developers.
              <div className="mb-2" key="descr" />
              Due to the ActiveAdmin solution with some Arctic Admin features,
              database administration of the collaboration marketplace appears
              to be incredibly comfortable and visually appealing.
            </>
          }
          alt="Admin part of the collaboration marketplace"
          imageTitle="Admin part on the Digital Village website"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredSeven}
          title="Enhanced UI/UX design"
          description="We built a robust online service marketplace with intuitive UX, as well as plain and simple UI design. The user interface allows clients to get smooth usage due to the adherence to well-defined UI/UX guidelines, developed by our team."
          alt="Example of UI/UX design by collaboration platfrom"
          imageTitle="Example of UI/UX design on the Digital Village website"
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“The outcome of our Beta version is perfect for our needs and the technology foundation is there for future rapid growth.',
            },
            {
              text: 'A partnership of trust and respect is reciprocated and we look forward to working with Codica into the future.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-dv.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseStudyTech}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'React', icon: 'react' },
            { label: 'Redux', icon: 'redux' },
            { label: 'JWT', icon: 'jwt' },
            { label: 'ActiveAdmin', icon: 'activeadmin' },
            { label: 'RSpec', icon: 'rspec' },
            { label: 'Semantic UI', icon: 'semantic' },
          ]}
          integrations={[
            { label: 'AWS', icon: 'aws' },
            { label: 'SendGrid', icon: 'sendgrid' },
            { label: 'Sentry.io', icon: 'sentry' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewTraderVsTitle}
              color="tradervsColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-tradervs.png"
                  alt={`Case study: ${previewTraderVsTitle} | Codica`}
                  title={`Case study: ${previewTraderVsTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="online-marketplace-for-caravans"
            />
            <CaseStudyPreview
              title={previewPmkTitle}
              color="pmkColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-pmk.png"
                  alt={`Case study: ${previewPmkTitle} | Codica`}
                  title={`Case study: ${previewPmkTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="kids-activities-service-marketplace"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesDigitalVillage
