// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b5_kr";
export var caseStudyBackground__lineColor = "b5_kp";
export var caseStudyHead__imageAbsolute = "b5_kl";
export var caseStudyHead__imageWrapper = "b5_kk";
export var caseStudyProjectsSection = "b5_ks";
export var caseStudyQuote__bgLight = "b5_kt";
export var caseStudyQuote__bgRing = "b5_km";
export var caseStudySolution__ring = "b5_ky";
export var caseStudyTech = "b5_kF";
export var caseStudy__bgDark = "b5_kh";
export var caseStudy__bgLight = "b5_kg";